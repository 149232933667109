<template>
  <module-container
    :hide-filters="hideFilters"
    :route-name="sRouteName"
    :show-toolbar="!hideFilters"
    :title="$t('invoices')"
    dense
  >
    <template #route>
      <router-view :key="$route.fullPath" />
    </template>

    <template #filters>
      <invoice-filters />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InvoiceFilters from "@/modules/invoices/components/InvoiceFilters.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component({
  components: { InvoiceFilters },
})
export default class InvoicesIndex extends Mixins(PaginateProviderMixin) {
  get sRouteName() {
    if (!this.$route.name) {
      return "invoices";
    }

    switch (this.$route.name) {
      case "invoices.update":
      case "invoices.create":
      case "invoices.copy":
      case "invoices.correct":
      case "invoices.view":
        return "invoices";

      case "invoices.receipt.update":
      case "invoices.receipt.create":
        return "invoices.receipt";

      case "invoices.debt.update":
      case "invoices.debt.create":
      case "invoices.debt.view":
        return "invoices.debt";
    }

    return "invoices";
  }

  get hideFilters() {
    const arRouteNameList = [
      "invoices.update",
      "invoices.create",
      "invoices.view",
      "invoices.receipt.update",
      "invoices.receipt.create",
      "invoices.debt.update",
      "invoices.debt.create",
      "invoices.debt.view",
      "invoices.copy",
      "invoices.correct",
    ];
    return this.$route.name
      ? this.$_.includes(arRouteNameList, this.$route.name)
      : false;
  }
}
</script>
